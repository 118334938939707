<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('게임 목록'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'games',
      response: '',
      body: {
        vendorKey: { type: 'onlyVendors', value: '', desc: this.$t('text.api.vendorKey_desc'), required: false }
      }
    };
  },
  mounted() {
    const host = process.env.VUE_APP_API_SERVER_URL
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }
     */
    this.apiUrl = host + 'games'

    // 응답값 설정
    this.response = '{\n' +
        '"code": 0, \t\t\t\t\t\t\t\t\t// 응답값(0이 아니면 오류)\n' +
        '  "length": 298, \t\t\t\t\t\t\t\t// 게임갯수\n' +
        '  "games": [ \t\t\t\t\t\t\t\t\t// 게임목록\n' +
        '    {\n' +
        '     "_id": "63059f1108b633b826d9f46f",\n' +
        '     "sort": 2,\n' +
        '     "active": 1\n' +
        '     "typeKey": "lobby",\n' +
        '     "maxBetAmount": 10000000,\n' +
        '     "names": {\n' +
        '      "ko": "lobby",\n' +
        '      "en": "lobby"\n' +
        '     },\n' +
        '     "image": "",\n' +
        '     "key": "lobby",\n' +
        '    },\n' +
        '    .\n' +
        '    .\n' +
        '    .\n' +
        '  ]\n' +
        '}'
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">{{ apiUrl }}</div>
            <div class="card-title-desc">{{ $t('text.api_games_desc') }}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="true" :require-ip-address="false" :require-request-key="false" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
